import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4339F2;
    text-decoration: none;
  }

  body {
    background-color: var(--color-background);
    color: ${(props) => props.theme.colors.text};
    font-family: DM Sans;
  }

  h1 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    @media (max-width: 768px) {
      font-size: calc(0.8*40px);
    
  }
  }

  h2 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
  }

  h3 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    @media (max-width: 768px) {
      font-size: calc(0.8*20px);
    }
  }

  h4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0px;
  }

  h5 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0px;
  }

  h6 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  select {
    -webkit-appearance: none; 
    appearance: auto;
    font-size: 16px;
    color: black;
  }

  input {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    
  }

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF38004D;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF3800;

}

  :root {
    --color-background: #F1F1F1;
    --color-white: #FFF;
    --color-black: #000;
    --color-orange-opaque: #ff380099;
    --color-green-opaque: #2DD88199;
    --color-blue-confirm: #2A4494;  
    --color-blue-opaque: #2A449466;  
    --color-blue-info: #9DD1F1;  
    --color-brown: #663E33;
    --color-gray: #F8F8F8;
    --color-blue: #4339F2;
    --color-green: #34B53A;
    --color-green-success: #E2FBD7;
    --color-red: #D83030;
    --color-input: #c2d6c9;
    --color-orange: #ff3800;
    --color-lever: #C5095A;
    --color-white-opaque: #FFFFFF66;
  }
`
