import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute } from "./auth.routes";

const BackofficeLogin = React.lazy(() => import("../screens/BackofficeLogin"));

const CompaniesList = React.lazy(
  () => import("../screens/Companies/CompaniesList")
);
const CompaniesDetails = React.lazy(
  () => import("../screens/Companies/CompaniesDetails")
);
const EmplooyeeDetails = React.lazy(
  () => import("../screens/Companies/EmployeeDetails")
);
const Controls = React.lazy(
  () => import("../screens/Controls"));

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/backofficelogin" exact component={BackofficeLogin} />

          <AuthRoute
            path="/companies/details/:company_id/employee/details/:user_id"
            component={EmplooyeeDetails}
          />

          <AuthRoute
            path="/companies/details/:company_id"
            component={CompaniesDetails}
          />
          <AuthRoute path="/companies/" component={CompaniesList} />

          <AuthRoute path="/controls" component={Controls} />

          <Route path="*" render={() => <Redirect to="/backofficelogin" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
